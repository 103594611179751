var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-3",attrs:{"no-body":""}},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"d-flex mt-1"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"18"}}),_c('h6',{staticClass:"mb-0 ml-50"},[_vm._v(" Employee ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"contactPerson","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"category-code","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Contact Person Name","disabled":_vm.actions.isPreview},model:{value:(_vm.form.picName),callback:function ($$v) {_vm.$set(_vm.form, "picName", $$v)},expression:"form.picName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"Employee ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"picCode","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataEmployee.placeholder.employeeID')  : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.picCode),callback:function ($$v) {_vm.$set(_vm.form, "picCode", $$v)},expression:"form.picCode"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +62 ")]),_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"disabled":_vm.actions.isPreview,"placeholder":"21 890 567"},model:{value:(_vm.form.picHandphone),callback:function ($$v) {_vm.$set(_vm.form, "picHandphone", $$v)},expression:"form.picHandphone"}})],1)],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"NPWP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"npwp","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataEmployee.placeholder.npwp')  : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.brandNpwp),callback:function ($$v) {_vm.$set(_vm.form, "brandNpwp", $$v)},expression:"form.brandNpwp"}})]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex mt-1 justify-content-between"},[_c('h6',{staticClass:"mb-0 ml-50"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"18"}}),_vm._v(" "+_vm._s(_vm.$t('apps.masterDataEmployee.singular.billingAddress'))+" ")],1)]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"address","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataEmployee.placeholder.billingAddressDetail')  : null,"disabled":_vm.actions.isPreview,"rows":"3"},model:{value:(_vm.form.billingAddress),callback:function ($$v) {_vm.$set(_vm.form, "billingAddress", $$v)},expression:"form.billingAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Kode POS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-mail","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Postal Code","disabled":_vm.actions.isPreview},model:{value:(_vm.form.billingPostalCode),callback:function ($$v) {_vm.$set(_vm.form, "billingPostalCode", $$v)},expression:"form.billingPostalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Region"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"region","options":_vm.LOV.regions,"reduce":function (field) { return field.code; },"getOptionLabel":function (option) { return option.province.name && option.name; },"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"City"},on:{"option:selected":_vm.handleRegion},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('small',[_vm._v(_vm._s(option.name)+" ( "+_vm._s(option.province.name)+" )")])]}}],null,true),model:{value:(_vm.form.billingRegionCode),callback:function ($$v) {_vm.$set(_vm.form, "billingRegionCode", $$v)},expression:"form.billingRegionCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"province","options":_vm.LOV.provinces,"reduce":function (field) { return field.code; },"label":"name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"province"},on:{"option:selected":_vm.handleProvince},model:{value:(_vm.form.billingProvinceCode),callback:function ($$v) {_vm.$set(_vm.form, "billingProvinceCode", $$v)},expression:"form.billingProvinceCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"mt-3 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":_vm.actions.isPreview ? 'outline-secondary' : 'outline-primary',"to":{ name: 'apps-master-data-employee-list'}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.backToList'))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:" mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.delete'))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t('globalActions.update') : _vm.$t('globalActions.create'))+" ")],1):_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.changeToEdit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.changeToEdit'))+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }