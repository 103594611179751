<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row align-h="center">
      <b-col
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
                   
            <!-- Header: Employee -->
            <div class="d-flex mt-1">
              <feather-icon
                icon="UserIcon"
                size="18"
              />
              <h6 class="mb-0 ml-50">
                Employee
              </h6>
            </div>

            <!-- contact Person-->
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="contactPerson"
                    rules="required"
                  >
                    <b-form-input
                      id="category-code"
                      size="sm"
                      v-model="form.picName"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Contact Person Name"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>  
              </b-col>

              <b-col md="4">
                <b-form-group
                  class="mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Employee ID"
                >
                  <b-form-input
                    id="picCode"
                    size="sm"
                    v-model="form.picCode"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="!actions.isPreview ? $t('apps.masterDataEmployee.placeholder.employeeID')  : null"
                    :disabled="actions.isPreview"
                  />
                </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                            <b-form-group>
                <b-input-group 
                    size="sm">
                  <b-input-group-prepend is-text>
                    +62
                  </b-input-group-prepend>
                  <cleave
                    id="phone"
                    v-model="form.picHandphone"
                    class="form-control"
                    :raw="false"
                    :options="options.phone"
                    :disabled="actions.isPreview"
                    placeholder="21 890 567"
                  />
                </b-input-group>
              </b-form-group>         

              </b-col>

              <b-col md="4">
                <b-form-group
                  class="mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="NPWP"
                >
                  <b-form-input
                    id="npwp"
                    size="sm"
                    v-model="form.brandNpwp"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="!actions.isPreview ? $t('apps.masterDataEmployee.placeholder.npwp')  : null"
                    :disabled="actions.isPreview"
                  />
                </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <!-- Header: Address -->
            <div class="d-flex mt-1 justify-content-between">
              
              <h6 class="mb-0 ml-50"> <feather-icon
                icon="MapPinIcon"
                size="18"
              />
                {{ $t('apps.masterDataEmployee.singular.billingAddress') }}
              </h6>

            </div>
            

            <!-- Address -->
            <b-row class="mt-1">

              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                  >
                    <b-form-textarea
                      id="address"
                      size="sm"
                      v-model="form.billingAddress"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataEmployee.placeholder.billingAddressDetail')  : null"
                      :disabled="actions.isPreview"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                
                </b-form-group>
              </b-col>
              <b-col md="2">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Kode POS"
                    >
                      <b-form-input
                        id="company-mail"
                        size="sm"
                        v-model="form.billingPostalCode"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Postal Code"
                        :disabled="actions.isPreview"
                      />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>  
              </b-col>
              <b-col md="5">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Region"
                  >
                    <v-select
                      id="region"
                      v-model="form.billingRegionCode"
                      :options="LOV.regions"
                      :reduce="field => field.code"
                      :getOptionLabel="option => option.province.name && option.name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      @option:selected="handleRegion"
                      placeholder="City"
                      class="select-size-sm">
                    <template v-slot:option="option">
                      <small>{{ option.name }} ( {{ option.province.name }} )</small>
                    </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Province"
                    rules="required"
                  >
                    <v-select
                      id="province"
                      v-model="form.billingProvinceCode"
                      :options="LOV.provinces"
                      :reduce="field => field.code"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      @option:selected="handleProvince"
                      class="select-size-sm"
                      placeholder="province"
                    />
                    <small class="text-danger">{{ errors[0]}}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
            </b-row>
            

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-master-data-employee-list'}"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton, 
  BInputGroupPrepend, 
  BInputGroup,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'

import 'cleave.js/dist/addons/cleave-phone.us'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    Cleave
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  setup () {
    const { 
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'master/contact', localeContextPath: 'apps.masterDataEmployee.singular.contact', redirectPathName: 'apps-master-data-employee' })

    // LOV (select option)
    const LOV = ref({
      districs: [],
      regions: [],
      provinces: [],
      accountAR: [],
      accountAP: []
    })

    const form = ref({
      type: ['CTEM'],
      brand: null,
      brandEmail: null,
      brandPhone: null,
      brandWebsite: null,
      brandNpwp: null,
      code: '',
      picName: '',
      picHandphone: null,
      billingAddress: null,
      billingCountryCode: null,
      billingProvinceCode: null,
      billingRegionCode: null, 
      billingPostalCode: null,
      shippingAddress: null,
      shippingCountryCode: null,
      shippingProvinceCode: null,
      shippingRegionCode: null,
      shippingPostalCode: null,
      receivableCoaMapping: null,
      payableCoaMapping: null
    })

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.type                   = data.type || []
      form.value.brand                  = data.brand
      form.value.brandEmail             = data.brand_email
      form.value.brandPhone             = data.brand_phone
      form.value.brandWebsite           = data.brand_website
      form.value.brandNpwp              = data.brand_npwp
      form.value.picCode                = data.pic_code
      form.value.picName                = data.pic_name
      form.value.picHandphone           = data.pic_handphone
      form.value.billingAddress         = data.billing_address
      form.value.billingCountryCode     = data.billing_country_code
      form.value.billingProvinceCode    = data.billing_province_code
      form.value.billingRegionCode      = data.billing_region_code 
      form.value.billingPostalCode      = data.billing_postal_code
      form.value.shippingAddress        = data.shipping_address
      form.value.shippingCountryCode    = data.shipping_country_code
      form.value.shippingProvinceCode   = data.shipping_province_code
      form.value.shippingRegionCode     = data.shipping_region_code
      form.value.shippingPostalCode     = data.shipping_postal_code
      form.value.receivableCoaMapping   = data.receivable_coa_mapping
      form.value.payableCoaMapping      = data.payable_coa_mapping
    }
  
    const getProvinces = async (params = '') => {
      LOV.value.provinces = await get({ url: `data/province${params}`})
    }
    const getRegions = async () => {
      LOV.value.regions = await get({ url: 'data/region'})
    }
    const getDistricts = async () => {
      LOV.value.districs = await get({ url: 'data/district'})
    }

    const handleRegion = ({province_code}) => {
      form.value.billingProvinceCode = province_code
    }

    const handleProvince = () => {
      form.value.billingRegionCode = null
    }

    watch(() => form.value.picName, newValue => {
      form.value.brand = newValue
    })

    onMounted(() => {
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      getProvinces()
      getRegions()
      getDistricts()
    })

    return {
      handleRegion,
      handleProvince,
      required,
      form,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      selected: [],
      LOV,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID'
        }
      }
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
